var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col"
  }, [_vm.searchBannerData && _vm.searchBannerData.url ? _c('div', {
    staticClass: "row margintop-20"
  }, [_c('b-img', {
    staticClass: "w-100 h-100",
    attrs: {
      "src": _vm.searchBannerData.url.fileUrl,
      "fluid": ""
    }
  })], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }